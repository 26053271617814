import { useResourceQuery } from "@/api/resources";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { resourcesRoute } from "../+route";

export const resourceRoute = createRoute({
  path: "$resourceId",
  getParentRoute: () => resourcesRoute,
  loader: ({ params: { resourceId } }) => {
    useResourceQuery.prefetch(resourceId);
  },
  validateSearch: valibotValidator(
    object({
      from: fallback(optional(string()), undefined),
    }),
  ),
  component: lazyRouteComponent(() => import("./+route.lazy")),
});
