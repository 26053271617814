import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, picklist, string } from "valibot";
import { authRoute } from "../__root";

export const mergeAccountRoute = createRoute({
  path: "merge-account",
  getParentRoute: () => authRoute,
  component: lazyRouteComponent(() => import("./+route.lazy")),
  validateSearch: valibotValidator(
    object({
      token: fallback(optional(string(), ""), ""),
      kind: picklist(["supporter", "direct"]),
    }),
  ),
});
