import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { rootRoute } from "../__root";

const searchSchema = object({
  utm: fallback(optional(string()), undefined),
  from: fallback(optional(string()), undefined),
});

export const nominateSupplierRoute = createRoute({
  path: "nominate",
  getParentRoute: () => rootRoute,
  validateSearch: valibotValidator(searchSchema),
  component: lazyRouteComponent(() => import("./+route.lazy")),
});
