import {
  BookingRequestAttachment,
  ConversationAttachment,
  FileConversationAttachment,
  ListingConversationAttachment,
  PostBookingRequestMessageBody,
  PostMemberMessageBody,
  PurchaseConversationAttachment,
} from "@givenwell/marketplace-api";
import { infiniteQueryOptions, useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { api } from "./utils";

export function useConversationsQuery() {
  return useQuery({
    queryKey: ["conversations", "list"],
    queryFn: async () => {
      const res = await api.messages.getMemberConversations();
      return res.conversations || [];
    },
  });
}

export function useConversationQuery(conversationId: string) {
  return useQuery({
    queryKey: ["conversations", "detail", conversationId],
    queryFn: async () => {
      const res = await api.messages.getMemberConversations();
      const conversations = res.conversations || [];
      return conversations.find(c => c.id === conversationId) || null;
    },
  });
}

type ChatPageParam = {
  before?: string;
  after?: string;
};

export const conversationMessagesQueryOptions = (conversationId: string) =>
  infiniteQueryOptions({
    queryKey: ["conversations", "detail", conversationId, "messages"],
    queryFn: async ({ pageParam }) => {
      const res = await api.messages.getMemberConversationMessages({
        conversationId,
        beforeThisMessageId: pageParam.before,
        afterThisMessageId: pageParam.after,
      });
      return res.messages || [];
    },
    initialPageParam: {
      before: undefined,
      after: undefined,
    } as ChatPageParam,
    getNextPageParam: (lastPage, allPages) => {
      const allMessages = allPages.flat();
      if (allMessages.length === 0) {
        return {
          before: undefined,
          after: undefined,
        };
      }
      const lastMessage = allMessages[allMessages.length - 1];
      return {
        before: undefined,
        after: lastMessage?.id,
      };
    },
    getPreviousPageParam: firstPage => {
      if (firstPage.length === 0) {
        return;
      }
      const firstMessage = firstPage[0];
      return {
        before: firstMessage?.id,
        after: undefined,
      };
    },
  });

export function useConversationMessagesQuery(conversationId: string) {
  return useInfiniteQuery(conversationMessagesQueryOptions(conversationId));
}

export function usePostMemberMessageMutation(conversationId: string) {
  return useMutation({
    mutationFn: async (requestBody: PostMemberMessageBody) => {
      await api.messages.postMemberMessage({ conversationId, requestBody });
    },
  });
}

export function useMarkMemberMessageSeenMutation() {
  return useMutation({
    mutationFn: async (messageId: string) => {
      await api.messages.markMemberMessageSeen({ messageId });
    },
  });
}

export function useDownloadMessageAttachmentMutation() {
  return useMutation({
    mutationFn: async (attachmentId: string) => {
      return await api.messages.downloadMessageAttachmentAsMember({ attachmentId });
    },
    onSuccess: () => {},
  });
}

export function useRequestBookingMutation() {
  return useMutation({
    mutationFn: async (args: { listingId: string; variantId: string; requestBody: PostBookingRequestMessageBody }) => {
      return await api.listings.postBookingRequestMessage(args);
    },
  });
}

export function isPurchaseAttachment(attachment: ConversationAttachment): attachment is PurchaseConversationAttachment {
  return attachment.$type === "purchase";
}
export function isFileAttachment(attachment: ConversationAttachment): attachment is FileConversationAttachment {
  return attachment.$type === "file";
}
export function isListingAttachment(attachment: ConversationAttachment): attachment is ListingConversationAttachment {
  return attachment.$type === "listing";
}
export function isBookingRequestAttachment(attachment: ConversationAttachment): attachment is BookingRequestAttachment {
  return attachment.$type === "booking_request";
}
