import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, finite, number, object, optional, pipe, toMaxValue, toMinValue } from "valibot";
import { topUpRoute } from "./+route";

/**
 * The maximum amount of tokens that can be purchased in a single transaction.
 * This is equivalent to $5,000 (in NZ/AU markets at least)
 */
const MAX_PURCHASE_AMOUNT = 25000;

const searchSchema = object({
  tokens: fallback(optional(pipe(number(), finite(), toMinValue(1), toMaxValue(MAX_PURCHASE_AMOUNT))), undefined),
});

export const topUpIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => topUpRoute,
  validateSearch: valibotValidator(searchSchema),
  component: lazyRouteComponent(() => import("./+index.lazy")),
});
