import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { object, optional, picklist } from "valibot";
import { appRoute } from "../+route";

const schema = object({
  filter: optional(picklist(["listings", "resources"])),
});

export const bookmarksRoute = createRoute({
  path: "favourites",
  getParentRoute: () => appRoute,
  component: lazyRouteComponent(() => import("./+Bookmarks")),
  validateSearch: valibotValidator(schema),
});
