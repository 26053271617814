import { myLocationQueryOptions } from "@/api/me";
import { queryClient } from "@/api/utils";
import { getIsTopUpEnabled } from "@/utils/topup";
import { createRoute, redirect } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { appRoute } from "../+route";

const searchSchema = object({
  "return-to": fallback(optional(string()), undefined),
});

export const topUpRoute = createRoute({
  path: "top-up",
  beforeLoad: async () => {
    const location = await queryClient.ensureQueryData(myLocationQueryOptions);
    const enabled = getIsTopUpEnabled({
      countryCode: location?.country_code,
    });
    if (!enabled) {
      throw redirect({
        to: "/marketplace",
      });
    }
  },
  validateSearch: valibotValidator(searchSchema),
  getParentRoute: () => appRoute,
});
