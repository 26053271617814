import { rootRoute } from "@/pages/__root";
import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, string } from "valibot";

const searchSchema = object({
  token: fallback(optional(string(), ""), ""),
});

export const activateInvitationRoute = createRoute({
  path: "invitation/activate",
  getParentRoute: () => rootRoute,
  component: lazyRouteComponent(() => import("./+route.lazy")),
  validateSearch: valibotValidator(searchSchema),
  beforeLoad: ({ search }) => {
    return {
      token: search.token,
    };
  },
  loader: ({ context }) => {
    if (!context.token) {
      throw redirect({
        to: "/",
      });
    }
  },
});
